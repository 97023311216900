//==========================================================================
//
//	Template Elements Stylesheet
//	____________________________________________________________
//
//	Date:		17/12/2018
//	Author:		Edge Marketing Solutions
//
//===========================================================================//
//                        [Font Settings]                                    //
//===========================================================================//
@import url('https://fonts.googleapis.com/css?family=Satisfy');
				  $font-decorative: "Satisfy", cursive;
					 $font-default: Arial, Vardana, sans-serif;
							$light: 300;
						  $regular: 400;
							 $bold: 700;

/*===========================================================================*/
/*                        [Colour Settings]                                  */
/*===========================================================================*/
					$color-primary: #10889e;
				  $color-secondary: #5dc3c0;
				   $color-tertiary: #6f6f6f;
				 $color-quaternary: #353639;
					$color-default: #4b4b4b;

//===========================================================================//
//                    [Header and Slider Overlay Settings]                   //
//===========================================================================//
			  $header-bg-color: white;
		 $banner-overlay-color: rgba(255, 255, 255, 0.3);
  $feature-image-overlay-color: rgba($color-secondary, 0.3);
				   $gradient-1: $color-primary;
				   $gradient-2: $color-secondary;
			   $slider-overlay: $color-primary;
	   $slider-overlay-opacity: 0.75;
	   $banner-overlay-opacity: $slider-overlay-opacity;

//===========================================================================//
//                    [Navigation Settings]                                  //
//===========================================================================//
						// Navbar Desktop
					  $nav-d-color: $color-tertiary;
				$nav-d-color-hover: $color-primary;

						// Navbar Desktop Dropdown
	      $nav-d-dropdown-bg-color: white;
	$nav-d-dropdown-bg-color-hover: $color-secondary;
	      	 $nav-d-dropdown-color: $color-primary;
	   $nav-d-dropdown-color-hover: $color-secondary;
	  $nav-d-dropdown-color-active: $color-secondary;
	  $nav-d-dropdown-border-color: solid 1px rgba($color-default, 0.33);

						// Navbar Mobile
					  $nav-m-color: $color-primary;
			   $nav-m-color-active: white;
			$nav-m-bg-color-active: $color-primary;
		  	   $nav-m-border-color: #d4d4d4;

					$nav-bg-toggle: $color-primary;
	   		 $nav-bg-toggle-active: darken($color-primary, 10%);
	   $nav-bg-toggle-active-color: white;
			  $nav-bg-toggle-color: white;

			 $nav-m-dropdown-color: $nav-m-color;
	  $nav-m-dropdown-color-active: white;
				$nav-m-dropdown-bg: white;
			 $nav-m-dropdown-li-bg: $nav-m-dropdown-bg;
	  $nav-m-dropdown-li-bg-active: $color-primary;
		$nav-m-dropdown-box-shadow: inset 0 0 10px 2px #C7C7C7;
				  $nav-m-li-border: 1px solid rgba(white, 0.5);

						$logo-text: $color-primary;
				  $logo-text-hover: $color-default;
				 $logo-text-mobile: $color-primary;
		   $logo-text-hover-mobile: $color-default;

			  $centre-select-color: $color-primary;
		$centre-select-color-hover: white;
		   $centre-select-bg-color: transparent;
	 $centre-select-bg-color-hover: $color-primary;
			 $centre-select-border: solid 1px transparentize($color-primary, 0.75);
	   $centre-select-border-hover: solid 1px transparentize($color-primary, 0.75);

//===========================================================================//
//                        [Slider Settings]                                  //
//===========================================================================//
				   $slider-overlay: $color-primary;
			 $slider-overlay-small: $color-secondary;
			   $slider-overlay-bar: rgba(white, 0.5);
	   		   $slider-text-shadow: none;
			  $slider-position-top: 85%;
			  		  $slider-dots: $color-tertiary;
			   $slider-dots-active: $color-primary;
					   $gradient-1: transparent;
					   $gradient-2: transparent;
					  $slider-font: $font-decorative;
				 $slider-font-size: 64px;

//===========================================================================//
//                  [Feature Navigation Settings]                            //
//===========================================================================//
                    $feature-nav-bg: $color-primary;
                 $feature-nav-color: white;
                 $feature-nav-title: white;
           $feature-nav-title-hover: white;
          $feature-nav-btn-bg-color: $color-secondary;
             $feature-nav-btn-color: darken($color-primary, 10%);
          $feature-nav-btn-bg-hover: white;
             $feature-nav-btn-hover: $color-primary;
       $feature-nav-btn-hover-color: $color-primary;
      $feature-nav-btn-border-color: $color-primary;
$feature-nav-btn-border-color-hover: $color-primary;
		    $feature_nav_item_width: 33.333%;

//===========================================================================//
//                  [Book Button Settings]                                   //
//===========================================================================//
				   $book-btn-color: white;
				   $book-btn-hover: $color-primary;
					  $book-btn-bg: $color-primary;
				$book-btn-bg-hover: white;
				  $book-btn-border: darken($color-primary, 10%);
		    $book-btn-border-hover: $color-primary;

/*===========================================================================*/
/*                      [Download Settings]                                  */
/*===========================================================================*/
				      $download-bg: $color-primary;
			$download-bg-secondary: $color-tertiary;
				   $download-color: white;
				   $download-title: $color-secondary;
				  $download-btn-bg: $color-secondary;
			   $download-btn-color: white;
			$download-btn-bg-hover: white;
		 $download-btn-color-hover: $color-primary;

/*===========================================================================*/
/*                        [Footer Settings]                                  */
/*===========================================================================*/
			            $footer-bg: $color-quaternary;
					 $footer-color: rgba(white, 0.66);
				   $footer-a-color: $footer-color;
				   $footer-a-hover: rgba(white, 1);
					   $row-social: $footer-color;
				 $row-social-hover: $footer-a-hover;
// [#8a3ab9 Purple Violet] [#4c68d7 Blue] [#cd486b Maroon] [#fbad50 Orange] [#fccc63 Yellow] [#bc2a8d Red Violet] [#e95950 Red Orange]
			 $row-social-instagram: $color-default;
			  $row-social-facebook: rgba(white, 0.66); // FB Blue: #3b5998
				 		$footer-h5: $color-primary;

/*=========================================================================*/

// [Global Template Styles] //
body {
	color: $color-default;
	font-family: $font-default;
	font-weight: $regular;
}

h1 {
	color: $color-primary;
	font-size: 35px;
	font-family: $font-default;
	font-weight: $regular;
	
	.logo-text a {
		color: $logo-text;
		
		&:hover,
		&:active,
		&:focus {
			color: $logo-text-hover;
		}
	}
}

h1 small
{
	color: $color-secondary;
}

h2 {
	color: $color-secondary;
	font-size: 28px;
	font-family: $font-default;
	font-weight: $regular;
}

h3 {
	color: $color-secondary;
	font-size: 20px;
	font-family: $font-default;
	
	&.blog-listing
	{
		margin-top: 0;
	}
}

h4 {
	color: $color-secondary;
	font-family: $font-default;
	font-weight: $bold;
}

h5 {
	font-weight: bold;
	text-transform: uppercase;
	font-size: 13px;
	font-family: $font-default;
}

h6 {
	font-weight: $regular;
	color: $color-primary;
	font-size: 35px;
	font-family: $font-default;
	margin: 10px 0 15px;
}

a {
	transition: all 150ms ease-in-out;
	color: $color-primary;
	
	&:hover,
	&:active,
	&:focus {
		color: $color-secondary;
		text-decoration: none;
	}
}

p {
	margin-bottom: 15px;
	line-height: 1.6em;
	
	&.lead {
		color: $color-tertiary;
		font-size: 18px;
		font-weight: $regular;
	}
}

.row-spaced {
	margin-bottom: 30px;
}

img {
	width: 100%;
}

img.svg-responsive {
	width: 100% \9;
}



//=========================================================================//
// [Header Template Styles] //
//=========================================================================//
.header {
	width: 100%;
	padding: 20px 0 30px;
	background: $header-bg-color;
	
	.h-logo {
		text-align: center;
		margin-top: 10px;
		
		img {
			width: 100%;
			max-width: 370px;
		}
	}
	
	.container-book
	{
		padding: 0;
	}
}

// Portrait tablet to landscape and desktop //
@media (min-width: 768px) and (max-width: 991px) {
	.header {
		.h-logo
		{
			text-align: center;
		}
	}
}


// Landscape phone to portrait //
@media (max-width: 767px) {
	.header {
		padding: 0 20px 20px 20px;
		
		.h-logo {
			margin-bottom: 20px;
		}
		
		.container-book
		{
			padding: 0;
		}
	}
	
	h1.logo-text a {
		color: $logo-text-mobile;
		text-align: center;
		
		&:hover,
		&:active,
		&:focus {
			color: $logo-text-hover-mobile;
		}
	}
}

//=========================================================================//
// [Navigation Template Styles] //
//=========================================================================//
ul.nav-desktop {
	margin: 65px 0 0 0;
	padding: 0;
	float: left;
	list-style-type: none;
	text-align: center;
	
	li {
		float: left;
		margin: 0 20px;
		position: relative;
		line-height: 1.2em;
		
		&:first-child {
			margin-left: 0;
		}
		
		&:last-child {
			margin-right: 0;
		}
		
		&.dual-line {
			margin-top: -8px;
		}
		
		a {
			transition: all 100ms ease-in-out;
			color: $nav-d-color;
			text-align: center!important;
			font-size: 13px;
			
			&:hover,
			&.active {
				color: $nav-d-color-hover;
				text-decoration: none;
			}
		}
		
		&.active a {
			color: $nav-d-color-hover;
			text-decoration: none;
		}
		
		&.dropdown-btn {
			&:hover {
				ul {
					border: $nav-d-dropdown-border-color;
					&.nav-desktop-dropdown-menu {
						display: block;
					}
				}
				
				a {
					color: $nav-d-color;
					text-decoration: none;
				}
			}
		}
	}
	
	> li > a {
		text-transform: uppercase;
	}
}

ul.nav-desktop-dropdown-menu {
	margin: 0;
	padding: 0 1em;
	position: absolute;
	width: 200px;
	left: 0;
	display: none;
	z-index: 1000;
	list-style-type: none;
	background-color: $nav-d-dropdown-bg-color;
	border-radius: 0 6px 6px 6px;
	box-shadow: 0 0 5px 0 rgba(0,0,0,0.25);
	
	li {
		margin: 15px 0 !important;
		padding: 0;
		float: none;
		text-align: left;
		
		a {
			color: $nav-d-dropdown-color !important;
			
			&:hover {
				color: $nav-d-dropdown-color-hover !important;
			}
		}
		
		&.active > a {
			color: $nav-d-dropdown-color-active !important;
		}
	}
}

.navbar-default {
	margin: 0;
	min-height: 10px;
	background-image: none;
	background-color: transparent;
	border: none;
	border-radius: 0;
	box-shadow: none;
	
	.navbar-collapse,
	.navbar-form {
		padding-left: 0;
		padding-right: 0;
		border: none;
	}
	
	.navbar-toggle {
		width: 100%;
		margin: 0;
		padding: 15px;
		background-color: $nav-bg-toggle;
		border: none;
		font-size: 16px;
		color: $nav-bg-toggle-color;
		
		&:active,
		&:hover,
		&:focus {
			background-color: $nav-bg-toggle-active;
			color: $nav-bg-toggle-active-color;
		}
	}
}

// Large desktop //
@media (min-width: 992px) and (max-width: 1199px) {
	ul.nav-desktop {
		margin: 40px 0 0 0;
		float: left;
		
		li {
			margin: 0 8px;
			
			a {
				font-size: 12px;
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.navbar-default {
		
		.navbar-nav {
			
			> li {
				
				a {
					padding: 20px 10px 0;
					color: $nav-d-color;
				}
				
				.dropdown-menu
				{
					> li
					{
						> a
						{
							font-size: 12px;
							color: $color-secondary;
							padding: 10px;
						}
					}
				}
			}
			
			> .active > a,
			> .active > a:hover,
			> .active > a:focus,
			> .open > a,
			> .open > a:hover,
			> .open > a:focus
			{
				background: transparent;
				color: $nav-d-color-hover;
			}
		}
	}
}

// Landscape phone to portrait tablet //
@media (max-width: 767px) {
	.navbar-default {
		
		.navbar-nav {
			margin: 0;
			border: solid 1px $nav-m-border-color;
			border-radius: 5px;
			background: $nav-m-dropdown-bg;
			
			> li {
				border-bottom: $nav-m-li-border;
				
				a {
					padding: 15px 18px;
					font-size: 14px;
					color: $nav-m-color;
					text-align: center;
				}
				
				&.active a
				{
					color: $nav-m-dropdown-color-active;
					background-color: $nav-m-bg-color-active;
				}
			}
			
			.open {
				.dropdown-menu {
					background-color: $nav-m-dropdown-li-bg;
					border: solid 1px $nav-m-border-color;
					box-shadow: $nav-m-dropdown-box-shadow;
					
					.active > a
					{
						background: $nav-m-dropdown-li-bg-active;
						color: $nav-m-dropdown-color-active;
					}
					
					> li > a {
						padding: 15px 18px;
						font-size: 14px;
						color: $nav-m-dropdown-color;
						text-align: center;
						background: $nav-m-dropdown-li-bg;
					}
				}
				
				> a,
				> a:hover,
				> a:focus
				{
					background-color: $nav-m-bg-color-active;
					color: white;
				}
			}
		}
	}
}

// Hover Click Fix //
//@media (min-width: 768px) {
//  .dropdown:hover .dropdown-menu {
//    display: block;
//    margin-top: 0;
//  }
//}


//=========================================================================//
// [Slick Slider Styles] //
//=========================================================================//
.slider-overlay
{
	//display: none; // Delete to turn overlay text on
	position: absolute;
	z-index: 1000;
	width: 100%;
	top: $slider-position-top;
	transform: translateY(-$slider-position-top);
	
	color: $slider-overlay;
	font-size: $slider-font-size;
	font-weight: $regular;
	line-height: 4rem;
	text-align: center;
	font-family: $slider-font;
	
	text-shadow: $slider-text-shadow;
	
	small
	{
		color: $slider-overlay-small;
		font-size: 36px;
		font-weight: 300;
	}
}

.slider-wrapper-banner {
	width: 100%;
	height: 145px;
	position: relative;
	z-index: 0;
	
	.overlay-bar {
		width: 100%;
		height: 12px;
		position: absolute;
		z-index: 2;
		background-color: $slider-overlay-bar;
		
		&.top-bar {
			top: 0;
		}
		
		&.bottom-bar {
			bottom: 0;
		}
	}
}

.slick-slide
{
	&:before
	{
		content: ' ';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+58,1+100 */
		background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 58%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 58%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 58%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
	}
}

.slick-slider {
	margin-bottom: 0;
}

.slider-wrapper {
	width: 100%;
	position: relative;
}

.overlay-bar {
	width: 100%;
	height: 12px;
	position: absolute;
	z-index: 200;
	background-color: $slider-overlay-bar;
}

.top-bar {
	top: 0;
}

.bottom-bar {
	bottom: 0;
}

.slickSlider {
	img {
		display: none;
	}
	
	&.slick-initialized img {
		display: block;
	}
	
	.slick-prev {
		left: 10px;
		z-index: 100;
		opacity: 0;
	}
	
	.slick-next {
		right: 10px;
		z-index: 100;
		opacity: 0;
	}
	
	&:hover {
		.slick-next,
		.slick-prev {
			opacity: 0.75;
		}
	}
	
	&.slick-dotted
	{
		margin-bottom: 0;
	}
	
	.slick-dots
	{
		bottom: 25px;
		
		li
		{
			button
			{
				&:before{
					color: $slider-dots;
					font-size: 18px;
					opacity: 1;
				}
				
				&:hover:before,
				&:focus:before
				{
					color: $slider-dots-active;
				}
			}
			
			&.slick-active button:before
			{
				color: $slider-dots-active;
			}
		}
	}
}

// Landscape phone to portrait tablet //
@media (max-width: 767px) {
	.slick-dots {
		display: none !important;
	}
}

//=========================================================================//
// [Feature Navigation Template Styles] //
//=========================================================================//
.feature-navigation {
	width: 100%;
	background-color: $feature-nav-bg;
	border-top: solid 2px white;
	
	h2 {
		padding: 0;
		margin: 0 0 12px 0;
		line-height: 1.0em;
		font-size: 22px;
		text-transform: uppercase;
		font-weight: bold;
		color: $feature-nav-title;
		
		a {
			color: $feature-nav-title;
			
			&:hover {
				color: $feature-nav-title-hover;
				text-decoration: none;
			}
		}
	}
	
	.btn {
		margin: 15px 0 0 0;
		border: 1px solid $feature-nav-btn-border-color;
		color: $feature-nav-btn-color;
		background: $feature-nav-btn-bg-color;
		
		&:hover {
			background-color: $feature-nav-btn-bg-hover;
			color: $feature-nav-btn-hover-color;
			border: 1px solid $feature-nav-btn-border-color-hover;
		}
	}
	
	&.subpage {
		height: 12px;
	}
	
	.item {
		width: $feature_nav_item_width;
		float: left;
		padding: 30px 10px;
		box-sizing: border-box;
		border-left: 1px solid rgba(255, 255, 255, 0.5);
		text-align: center;
		color: $feature-nav-color;
		font-size: 18px;
		
		&:last-child {
			border-right: 1px solid rgba(255, 255, 255, 0.5);
		}
	}
}

.feature-navigation-arrow {
	width: 0;
	height: 0;
	margin: 0 auto;
	border-style: solid;
	border-width: 26px 135px 0 135px;
	border-color: $feature-nav-bg transparent transparent transparent;
}

// Large desktop //
@media (min-width: 1200px) {
	.feature-navigation .item h2 {
		font-size: 28px;
	}
}

// Portrait tablet to landscape and desktop //
@media (min-width: 768px) and (max-width: 991px) {
	.feature-navigation .item {
		font-size: 15px;
		
		h2 {
			font-size: 20px;
		}
	}
	
}

// Landscape phone to portrait tablet //
@media (max-width: 767px) {
	.feature-navigation {
		.item {
			width: 100%;
			float: left;
			padding: 20px 0;
			box-sizing: border-box;
			border-top: 1px solid rgba(255, 255, 255, 0.5);
			border-left: none;
			text-align: center;
			color: $feature-nav-title;
			font-size: 12px;
			
			h2 {
				font-size: 18px;
			}
			
			.btn {
				margin-top: 8px;
				width: 100%;
				text-align: center;
			}
			
			&:last-child {
				border-right: none;
			}
		}
	}
}

//=========================================================================//
// [Page Template Styles] //
//=========================================================================//
.page {
	padding: 20px 0 0 0;
	min-height: 50vh;
	
	&.homepage
	{
		text-align: left;
		min-height: auto;
	}
}

//=========================================================================//
// [Buttons Template Styles] //
//=========================================================================//
.btn
{
	transition: all 200ms ease-in-out;
}
.btn-primary {
	background-color: $color-primary !important;
	border-color: $color-primary !important;
	color: white !important;
	
	&:not(:disabled):not(.disabled):active {
		background-color: darken($color-primary, 10) !important;
		border-color: darken($color-primary, 10) !important;
		color: white;
	}
	
	&:hover {
		background-color: white !important;
		color: black !important;
		border-color: black !important;
	}
	
	&:focus,
	&.focus,
	&:active {
		box-shadow: 0 0 0 0.2rem rgba($color-primary, 0.5) !important;
	}
}
.btn-book {
	margin-top: 10px;
	background-color:  $book-btn-bg !important;
	border-color:  $book-btn-border !important;
	color: $book-btn-color !important;
	
	&:not(:disabled):not(.disabled):active {
		background-color: darken( $color-secondary, 10) !important;
		border-color: darken( $color-secondary, 10) !important;
		color: white;
	}
	
	&:hover {
		border-color: $book-btn-border-hover!important;
		background: $book-btn-bg-hover!important;
		color: $book-btn-hover!important;
	}
	
	&:focus,
	&.focus,
	&:active {
		box-shadow: 0 0 0 0.2rem rgba($color-tertiary, 0.5) !important;
	}
}

.btn-success {
	background-color: $color-primary;
	border-color: darken($color-primary, 15%);
	
	&:hover
	{
		background-color: white !important;
		color: black !important;
		border-color: black !important;
	}
}

.btn-warning {
	background-color: $color-tertiary;
	border-color: darken($color-tertiary, 5%);
	border-radius: 0;
	
	&:hover
	{
		background-color: white !important;
		color: black !important;
		border-color: black !important;
	}
}

.btn-phone {
	background-color: $color-primary;
	border-color: darken($color-primary, 5%);
	border-radius: 0;
	color: white;
	padding: 12px;
	
	&:hover
	{
		background-color: white !important;
		color: black !important;
		border-color: black !important;
	}
}

.container-book {
	position: relative;
	
	.book-btn-header {
		position: absolute;
		z-index: 1000;
		top: 0;
		right: 0;
		
		a {
			border: solid 1px $book-btn-border;
			color: $book-btn-color;
			background: $book-btn-bg;
			padding: 6px 15px;
			font-size: 14px;
			border-radius: 5px;
			
			&:hover,
			&:active,
			&:focus {
				border-color: $book-btn-border-hover;
				background: $book-btn-bg-hover;
				color: $book-btn-hover
			}
		}
	}
}

@media(max-width: 767px) {
	.container-book {
		.book-btn-header {
			position: relative;
			width: 100%;
			top: 0;
			
			a {
				width: 100%;
				display: block;
				padding: 12px 20px;
				font-size: 16px;
				border-radius: 0;
			}
		}
	}
}

//=========================================================================//
// [Footer Template Styles] //
//=========================================================================//
.footer {
	width: 100%;
	padding: 0 0 30px 0;
	margin: 40px 0 0 0;
	background-color: $footer-bg;
	color: $footer-color;
	font-size: 12px;
	
	.arrow {
		width: 0;
		height: 0;
		margin: 0 auto 30px auto;
		border-style: solid;
		border-width: 26px 135px 0 135px;
		border-color: white transparent transparent transparent;
	}
	
	h5 {
		margin-top: 0;
		color: $footer-h5
	}
	
	a {
		color: $footer-a-color;
		
		&:hover {
			color: $footer-a-hover;
			text-decoration: none;
		}
		
		.btn {
			margin: 0 0 30px 0;
			background-color: rgba(0, 0, 0, 0.4);
			color: white;
			
			&:hover {
				background-color: white;
				color: rgba(0, 0, 0, 0.8);
			}
		}
	}
	
	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
		
		> li {
			padding: 5px 0;
			border-top: 1px solid rgba(0, 0, 0, 0.1);
		}
	}
	
	.row-social {
		margin-bottom: 10px;
		
		font-size: 30px;
		text-align: right;
		
		a {
			color: $row-social;
			
			&.facebook
			{
				color: $row-social-facebook;
			}
			
			&.instagram
			{
				font-size: 3.5rem;
				margin-left: 5px;
				color: $row-social-instagram;
			}
			
			&:hover {
				color: $row-social-hover;
			}
		}
	}
}


.logo-chiropractic-iq,
.logo-caa {
	transition: all 150ms ease-in-out;
	width: 100%;
	opacity: 0.5;
	float: right;
	
	&:hover {
		opacity: 1;
	}
}

.logo-caa {
	width: 100%;
	margin-bottom: 10px;
}

// Landscape phone to portrait tablet //
@media (max-width: 767px) {
	.footer {
		text-align: center;
		
		h5 {
			margin: 30px 0 10px 0;
		}
		
		.row-social {
			font-size: 40px;
			margin: 0;
			text-align: center;
			
			a.instagram
			{
				font-size: 4.9rem;
				margin-left: 20px;
			}
		}
	}
	.logo-chiropractic-iq {
		margin: 20px auto;
		width: 50%;
		float: none;
	}
	.logo-caa {
		width: 50%;
		margin: 40px auto 0 auto;
		float: none;
	}
}

//=========================================================================//
// [Download Template Styles] //
//=========================================================================//
.download {
	width: 100%;
	margin: 0 0 20px 0;
	float: left;
	
	.icon {
		width: 100%;
		float: left;
		height: 120px;
		background-image: url('../images/ebook-icon.jpg');
		background-repeat: no-repeat;
		background-size: cover;
	}
	
	.details {
		width: 100%;
		padding: 15px;
		height: 100%;
		float: left;
		background-color: $download-bg;
		color: $download-color;
		text-align: center;
		
		h3 {
			margin: 0 0 5px;
			padding: 0;
			color: $download-title;
			line-height: 1.0em;
			font-size: 16px;
			font-weight: bold;
		}
		
		a {
			&.btn {
				margin: 8px 0 0 0;
				background-color: $download-btn-bg;
				color: $download-btn-color;
				
				&:hover {
					background-color: $download-btn-bg-hover;
					color: $download-btn-color-hover;
				}
			}
		}
		
		&.secondary {
			background-color: $download-bg-secondary!important;
			
			a {
				&.btn {
					
					&:hover {
						background-color: darken($download-bg-secondary, 20%);
						color: $download-btn-color-hover;
					}
				}
			}
		}
	}
}

// Large desktop //
@media (min-width: 1200px) {
	.download .details h3 {
		font-size: 20px;
	}
}

// Portrait tablet to landscape and desktop //
@media (min-width: 768px) and (max-width: 991px) {
	.download {
		height: auto;
		
		.icon {
			width: 100%;
			height: 100px;
		}
		
		.details {
			width: 100%;
			height: auto;
			text-align: center;
			
			h3 {
				font-size: 17px;
				font-weight: bold;
			}
		}
	}
}


//=========================================================================//
// [Infosheets Template Styles] //
//=========================================================================//
.infosheets {
	padding: 15px;
	width: 100%;
	float: left;
	background-color: rgba(175, 175, 175, 0.2);
	
	h3 {
		margin: 0;
		padding: 0;
		font-size: 18px;
		color: $color-default;
		text-align: center;
		font-weight: bold;
	}
	
	ul {
		margin: 15px 0 0 0;
		padding: 0;
		list-style-type: none;
	}
	
	ul > li {
		margin: 2px 0;
		border: 1px solid rgba(0, 0, 0, 0.1);
		
		a {
			display: block;
			padding: 9px 15px 9px 55px;
			background-image: url('../images/template-1/Adobe_PDF_file_icon_24x24.png');
			background-repeat: no-repeat;
			background-position: 15px center;
			color: $color-default;
			
			&:hover {
				text-decoration: none;
				background-color: white;
			}
		}
	}
}

//=========================================================================//
// [Bootstrap Row Clear Fix Styles] //
#media-query-detector {
	display: none;
	width: 0;
}

@media (min-width: 768px) {
	#media-query-detector {
		width: 768px;
	}
}

@media (min-width: 992px) {
	#media-query-detector {
		width: 992px;
	}
}

@media (min-width: 1200px) {
	#media-query-detector {
		width: 1200px;
	}
}

.row-fix-clear-both {
	clear: both;
}

//=========================================================================//
// [Table as row override Styles] //
table.table-as-row > tbody > tr:before,
table.table-as-row > tbody > tr:after {
	content: " ";
	display: table;
	clear: both;
}

table.table-as-row > tbody > tr {
	height: auto !important;
}

table.table-as-row,
table.table-as-row > tbody,
table.table-as-row > tbody > tr {
	display: block;
	width: auto;
}

table.table-as-row > tbody > tr {
	display: block;
	width: auto;
	margin-right: -15px;
	margin-left: -15px;
}

table.table-as-row > tbody > tr > td[class*=col-] {
	display: block;
	height: auto !important;
	margin-bottom: 20px;
}

// col-lg //
@media (min-width: 1200px) {
	table.table-as-row > tbody > tr > td[class*=col-lg-] {
		float: left;
	}
}

// col-md //
@media (min-width: 992px) {
	table.table-as-row > tbody > tr > td[class*=col-md-] {
		float: left;
	}
}

// col-sm //
@media (min-width: 768px) {
	table.table-as-row > tbody > tr > td[class*=col-sm-] {
		float: left;
	}
}

// col-xs //
table.table-as-row > tbody > tr > td[class*=col-xs-] {
	float: left;
}