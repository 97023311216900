@import url("https://fonts.googleapis.com/css?family=Satisfy");
/*===========================================================================*/
/*                        [Colour Settings]                                  */
/*===========================================================================*/
/*===========================================================================*/
/*                      [Download Settings]                                  */
/*===========================================================================*/
/*===========================================================================*/
/*                        [Footer Settings]                                  */
/*===========================================================================*/
/*=========================================================================*/
body {
  color: #4b4b4b;
  font-family: Arial, Vardana, sans-serif;
  font-weight: 400;
}

h1 {
  color: #10889e;
  font-size: 35px;
  font-family: Arial, Vardana, sans-serif;
  font-weight: 400;
}

h1 .logo-text a {
  color: #10889e;
}

h1 .logo-text a:hover, h1 .logo-text a:active, h1 .logo-text a:focus {
  color: #4b4b4b;
}

h1 small {
  color: #5dc3c0;
}

h2 {
  color: #5dc3c0;
  font-size: 28px;
  font-family: Arial, Vardana, sans-serif;
  font-weight: 400;
}

h3 {
  color: #5dc3c0;
  font-size: 20px;
  font-family: Arial, Vardana, sans-serif;
}

h3.blog-listing {
  margin-top: 0;
}

h4 {
  color: #5dc3c0;
  font-family: Arial, Vardana, sans-serif;
  font-weight: 700;
}

h5 {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  font-family: Arial, Vardana, sans-serif;
}

h6 {
  font-weight: 400;
  color: #10889e;
  font-size: 35px;
  font-family: Arial, Vardana, sans-serif;
  margin: 10px 0 15px;
}

a {
  transition: all 150ms ease-in-out;
  color: #10889e;
}

a:hover, a:active, a:focus {
  color: #5dc3c0;
  text-decoration: none;
}

p {
  margin-bottom: 15px;
  line-height: 1.6em;
}

p.lead {
  color: #6f6f6f;
  font-size: 18px;
  font-weight: 400;
}

.row-spaced {
  margin-bottom: 30px;
}

img {
  width: 100%;
}

img.svg-responsive {
  width: 100% \9;
}

.header {
  width: 100%;
  padding: 20px 0 30px;
  background: white;
}

.header .h-logo {
  text-align: center;
  margin-top: 10px;
}

.header .h-logo img {
  width: 100%;
  max-width: 370px;
}

.header .container-book {
  padding: 0;
}

@media (min-width: 768px) and (max-width: 991px) {
  .header .h-logo {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .header {
    padding: 0 20px 20px 20px;
  }
  .header .h-logo {
    margin-bottom: 20px;
  }
  .header .container-book {
    padding: 0;
  }
  h1.logo-text a {
    color: #10889e;
    text-align: center;
  }
  h1.logo-text a:hover, h1.logo-text a:active, h1.logo-text a:focus {
    color: #4b4b4b;
  }
}

ul.nav-desktop {
  margin: 65px 0 0 0;
  padding: 0;
  float: left;
  list-style-type: none;
  text-align: center;
}

ul.nav-desktop li {
  float: left;
  margin: 0 20px;
  position: relative;
  line-height: 1.2em;
}

ul.nav-desktop li:first-child {
  margin-left: 0;
}

ul.nav-desktop li:last-child {
  margin-right: 0;
}

ul.nav-desktop li.dual-line {
  margin-top: -8px;
}

ul.nav-desktop li a {
  transition: all 100ms ease-in-out;
  color: #6f6f6f;
  text-align: center !important;
  font-size: 13px;
}

ul.nav-desktop li a:hover, ul.nav-desktop li a.active {
  color: #10889e;
  text-decoration: none;
}

ul.nav-desktop li.active a {
  color: #10889e;
  text-decoration: none;
}

ul.nav-desktop li.dropdown-btn:hover ul {
  border: solid 1px rgba(75, 75, 75, 0.33);
}

ul.nav-desktop li.dropdown-btn:hover ul.nav-desktop-dropdown-menu {
  display: block;
}

ul.nav-desktop li.dropdown-btn:hover a {
  color: #6f6f6f;
  text-decoration: none;
}

ul.nav-desktop > li > a {
  text-transform: uppercase;
}

ul.nav-desktop-dropdown-menu {
  margin: 0;
  padding: 0 1em;
  position: absolute;
  width: 200px;
  left: 0;
  display: none;
  z-index: 1000;
  list-style-type: none;
  background-color: white;
  border-radius: 0 6px 6px 6px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
}

ul.nav-desktop-dropdown-menu li {
  margin: 15px 0 !important;
  padding: 0;
  float: none;
  text-align: left;
}

ul.nav-desktop-dropdown-menu li a {
  color: #10889e !important;
}

ul.nav-desktop-dropdown-menu li a:hover {
  color: #5dc3c0 !important;
}

ul.nav-desktop-dropdown-menu li.active > a {
  color: #5dc3c0 !important;
}

.navbar-default {
  margin: 0;
  min-height: 10px;
  background-image: none;
  background-color: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  padding-left: 0;
  padding-right: 0;
  border: none;
}

.navbar-default .navbar-toggle {
  width: 100%;
  margin: 0;
  padding: 15px;
  background-color: #10889e;
  border: none;
  font-size: 16px;
  color: white;
}

.navbar-default .navbar-toggle:active, .navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
  background-color: #0b6070;
  color: white;
}

@media (min-width: 992px) and (max-width: 1199px) {
  ul.nav-desktop {
    margin: 40px 0 0 0;
    float: left;
  }
  ul.nav-desktop li {
    margin: 0 8px;
  }
  ul.nav-desktop li a {
    font-size: 12px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .navbar-default .navbar-nav > li a {
    padding: 20px 10px 0;
    color: #6f6f6f;
  }
  .navbar-default .navbar-nav > li .dropdown-menu > li > a {
    font-size: 12px;
    color: #5dc3c0;
    padding: 10px;
  }
  .navbar-default .navbar-nav > .active > a,
  .navbar-default .navbar-nav > .active > a:hover,
  .navbar-default .navbar-nav > .active > a:focus,
  .navbar-default .navbar-nav > .open > a,
  .navbar-default .navbar-nav > .open > a:hover,
  .navbar-default .navbar-nav > .open > a:focus {
    background: transparent;
    color: #10889e;
  }
}

@media (max-width: 767px) {
  .navbar-default .navbar-nav {
    margin: 0;
    border: solid 1px #d4d4d4;
    border-radius: 5px;
    background: white;
  }
  .navbar-default .navbar-nav > li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
  .navbar-default .navbar-nav > li a {
    padding: 15px 18px;
    font-size: 14px;
    color: #10889e;
    text-align: center;
  }
  .navbar-default .navbar-nav > li.active a {
    color: white;
    background-color: #10889e;
  }
  .navbar-default .navbar-nav .open .dropdown-menu {
    background-color: white;
    border: solid 1px #d4d4d4;
    box-shadow: inset 0 0 10px 2px #C7C7C7;
  }
  .navbar-default .navbar-nav .open .dropdown-menu .active > a {
    background: #10889e;
    color: white;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    padding: 15px 18px;
    font-size: 14px;
    color: #10889e;
    text-align: center;
    background: white;
  }
  .navbar-default .navbar-nav .open > a,
  .navbar-default .navbar-nav .open > a:hover,
  .navbar-default .navbar-nav .open > a:focus {
    background-color: #10889e;
    color: white;
  }
}

.slider-overlay {
  position: absolute;
  z-index: 1000;
  width: 100%;
  top: 85%;
  transform: translateY(-85%);
  color: #10889e;
  font-size: 64px;
  font-weight: 400;
  line-height: 4rem;
  text-align: center;
  font-family: "Satisfy", cursive;
  text-shadow: none;
}

.slider-overlay small {
  color: #5dc3c0;
  font-size: 36px;
  font-weight: 300;
}

.slider-wrapper-banner {
  width: 100%;
  height: 145px;
  position: relative;
  z-index: 0;
}

.slider-wrapper-banner .overlay-bar {
  width: 100%;
  height: 12px;
  position: absolute;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.5);
}

.slider-wrapper-banner .overlay-bar.top-bar {
  top: 0;
}

.slider-wrapper-banner .overlay-bar.bottom-bar {
  bottom: 0;
}

.slick-slide:before {
  content: ' ';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+58,1+100 */
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 58%, white 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 58%, white 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 58%, white 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
  /* IE6-9 */
}

.slick-slider {
  margin-bottom: 0;
}

.slider-wrapper {
  width: 100%;
  position: relative;
}

.overlay-bar {
  width: 100%;
  height: 12px;
  position: absolute;
  z-index: 200;
  background-color: rgba(255, 255, 255, 0.5);
}

.top-bar {
  top: 0;
}

.bottom-bar {
  bottom: 0;
}

.slickSlider img {
  display: none;
}

.slickSlider.slick-initialized img {
  display: block;
}

.slickSlider .slick-prev {
  left: 10px;
  z-index: 100;
  opacity: 0;
}

.slickSlider .slick-next {
  right: 10px;
  z-index: 100;
  opacity: 0;
}

.slickSlider:hover .slick-next,
.slickSlider:hover .slick-prev {
  opacity: 0.75;
}

.slickSlider.slick-dotted {
  margin-bottom: 0;
}

.slickSlider .slick-dots {
  bottom: 25px;
}

.slickSlider .slick-dots li button:before {
  color: #6f6f6f;
  font-size: 18px;
  opacity: 1;
}

.slickSlider .slick-dots li button:hover:before, .slickSlider .slick-dots li button:focus:before {
  color: #10889e;
}

.slickSlider .slick-dots li.slick-active button:before {
  color: #10889e;
}

@media (max-width: 767px) {
  .slick-dots {
    display: none !important;
  }
}

.feature-navigation {
  width: 100%;
  background-color: #10889e;
  border-top: solid 2px white;
}

.feature-navigation h2 {
  padding: 0;
  margin: 0 0 12px 0;
  line-height: 1.0em;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
}

.feature-navigation h2 a {
  color: white;
}

.feature-navigation h2 a:hover {
  color: white;
  text-decoration: none;
}

.feature-navigation .btn {
  margin: 15px 0 0 0;
  border: 1px solid #10889e;
  color: #0b6070;
  background: #5dc3c0;
}

.feature-navigation .btn:hover {
  background-color: white;
  color: #10889e;
  border: 1px solid #10889e;
}

.feature-navigation.subpage {
  height: 12px;
}

.feature-navigation .item {
  width: 33.333%;
  float: left;
  padding: 30px 10px;
  box-sizing: border-box;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  text-align: center;
  color: white;
  font-size: 18px;
}

.feature-navigation .item:last-child {
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}

.feature-navigation-arrow {
  width: 0;
  height: 0;
  margin: 0 auto;
  border-style: solid;
  border-width: 26px 135px 0 135px;
  border-color: #10889e transparent transparent transparent;
}

@media (min-width: 1200px) {
  .feature-navigation .item h2 {
    font-size: 28px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .feature-navigation .item {
    font-size: 15px;
  }
  .feature-navigation .item h2 {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .feature-navigation .item {
    width: 100%;
    float: left;
    padding: 20px 0;
    box-sizing: border-box;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: none;
    text-align: center;
    color: white;
    font-size: 12px;
  }
  .feature-navigation .item h2 {
    font-size: 18px;
  }
  .feature-navigation .item .btn {
    margin-top: 8px;
    width: 100%;
    text-align: center;
  }
  .feature-navigation .item:last-child {
    border-right: none;
  }
}

.page {
  padding: 20px 0 0 0;
  min-height: 50vh;
}

.page.homepage {
  text-align: left;
  min-height: auto;
}

.btn {
  transition: all 200ms ease-in-out;
}

.btn-primary {
  background-color: #10889e !important;
  border-color: #10889e !important;
  color: white !important;
}

.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #0b6070 !important;
  border-color: #0b6070 !important;
  color: white;
}

.btn-primary:hover {
  background-color: white !important;
  color: black !important;
  border-color: black !important;
}

.btn-primary:focus, .btn-primary.focus, .btn-primary:active {
  box-shadow: 0 0 0 0.2rem rgba(16, 136, 158, 0.5) !important;
}

.btn-book {
  margin-top: 10px;
  background-color: #10889e !important;
  border-color: #0b6070 !important;
  color: white !important;
}

.btn-book:not(:disabled):not(.disabled):active {
  background-color: #40adaa !important;
  border-color: #40adaa !important;
  color: white;
}

.btn-book:hover {
  border-color: #10889e !important;
  background: white !important;
  color: #10889e !important;
}

.btn-book:focus, .btn-book.focus, .btn-book:active {
  box-shadow: 0 0 0 0.2rem rgba(111, 111, 111, 0.5) !important;
}

.btn-success {
  background-color: #10889e;
  border-color: #094c59;
}

.btn-success:hover {
  background-color: white !important;
  color: black !important;
  border-color: black !important;
}

.btn-warning {
  background-color: #6f6f6f;
  border-color: #626262;
  border-radius: 0;
}

.btn-warning:hover {
  background-color: white !important;
  color: black !important;
  border-color: black !important;
}

.btn-phone {
  background-color: #10889e;
  border-color: #0e7487;
  border-radius: 0;
  color: white;
  padding: 12px;
}

.btn-phone:hover {
  background-color: white !important;
  color: black !important;
  border-color: black !important;
}

.container-book {
  position: relative;
}

.container-book .book-btn-header {
  position: absolute;
  z-index: 1000;
  top: 0;
  right: 0;
}

.container-book .book-btn-header a {
  border: solid 1px #0b6070;
  color: white;
  background: #10889e;
  padding: 6px 15px;
  font-size: 14px;
  border-radius: 5px;
}

.container-book .book-btn-header a:hover, .container-book .book-btn-header a:active, .container-book .book-btn-header a:focus {
  border-color: #10889e;
  background: white;
  color: #10889e;
}

@media (max-width: 767px) {
  .container-book .book-btn-header {
    position: relative;
    width: 100%;
    top: 0;
  }
  .container-book .book-btn-header a {
    width: 100%;
    display: block;
    padding: 12px 20px;
    font-size: 16px;
    border-radius: 0;
  }
}

.footer {
  width: 100%;
  padding: 0 0 30px 0;
  margin: 40px 0 0 0;
  background-color: #353639;
  color: rgba(255, 255, 255, 0.66);
  font-size: 12px;
}

.footer .arrow {
  width: 0;
  height: 0;
  margin: 0 auto 30px auto;
  border-style: solid;
  border-width: 26px 135px 0 135px;
  border-color: white transparent transparent transparent;
}

.footer h5 {
  margin-top: 0;
  color: #10889e;
}

.footer a {
  color: rgba(255, 255, 255, 0.66);
}

.footer a:hover {
  color: white;
  text-decoration: none;
}

.footer a .btn {
  margin: 0 0 30px 0;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}

.footer a .btn:hover {
  background-color: white;
  color: rgba(0, 0, 0, 0.8);
}

.footer ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.footer ul > li {
  padding: 5px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footer .row-social {
  margin-bottom: 10px;
  font-size: 30px;
  text-align: right;
}

.footer .row-social a {
  color: rgba(255, 255, 255, 0.66);
}

.footer .row-social a.facebook {
  color: rgba(255, 255, 255, 0.66);
}

.footer .row-social a.instagram {
  font-size: 3.5rem;
  margin-left: 5px;
  color: #4b4b4b;
}

.footer .row-social a:hover {
  color: white;
}

.logo-chiropractic-iq,
.logo-caa {
  transition: all 150ms ease-in-out;
  width: 100%;
  opacity: 0.5;
  float: right;
}

.logo-chiropractic-iq:hover,
.logo-caa:hover {
  opacity: 1;
}

.logo-caa {
  width: 100%;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .footer {
    text-align: center;
  }
  .footer h5 {
    margin: 30px 0 10px 0;
  }
  .footer .row-social {
    font-size: 40px;
    margin: 0;
    text-align: center;
  }
  .footer .row-social a.instagram {
    font-size: 4.9rem;
    margin-left: 20px;
  }
  .logo-chiropractic-iq {
    margin: 20px auto;
    width: 50%;
    float: none;
  }
  .logo-caa {
    width: 50%;
    margin: 40px auto 0 auto;
    float: none;
  }
}

.download {
  width: 100%;
  margin: 0 0 20px 0;
  float: left;
}

.download .icon {
  width: 100%;
  float: left;
  height: 120px;
  background-image: url("../images/ebook-icon.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.download .details {
  width: 100%;
  padding: 15px;
  height: 100%;
  float: left;
  background-color: #10889e;
  color: white;
  text-align: center;
}

.download .details h3 {
  margin: 0 0 5px;
  padding: 0;
  color: #5dc3c0;
  line-height: 1.0em;
  font-size: 16px;
  font-weight: bold;
}

.download .details a.btn {
  margin: 8px 0 0 0;
  background-color: #5dc3c0;
  color: white;
}

.download .details a.btn:hover {
  background-color: white;
  color: #10889e;
}

.download .details.secondary {
  background-color: #6f6f6f !important;
}

.download .details.secondary a.btn:hover {
  background-color: #3c3c3c;
  color: #10889e;
}

@media (min-width: 1200px) {
  .download .details h3 {
    font-size: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .download {
    height: auto;
  }
  .download .icon {
    width: 100%;
    height: 100px;
  }
  .download .details {
    width: 100%;
    height: auto;
    text-align: center;
  }
  .download .details h3 {
    font-size: 17px;
    font-weight: bold;
  }
}

.infosheets {
  padding: 15px;
  width: 100%;
  float: left;
  background-color: rgba(175, 175, 175, 0.2);
}

.infosheets h3 {
  margin: 0;
  padding: 0;
  font-size: 18px;
  color: #4b4b4b;
  text-align: center;
  font-weight: bold;
}

.infosheets ul {
  margin: 15px 0 0 0;
  padding: 0;
  list-style-type: none;
}

.infosheets ul > li {
  margin: 2px 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.infosheets ul > li a {
  display: block;
  padding: 9px 15px 9px 55px;
  background-image: url("../images/template-1/Adobe_PDF_file_icon_24x24.png");
  background-repeat: no-repeat;
  background-position: 15px center;
  color: #4b4b4b;
}

.infosheets ul > li a:hover {
  text-decoration: none;
  background-color: white;
}

#media-query-detector {
  display: none;
  width: 0;
}

@media (min-width: 768px) {
  #media-query-detector {
    width: 768px;
  }
}

@media (min-width: 992px) {
  #media-query-detector {
    width: 992px;
  }
}

@media (min-width: 1200px) {
  #media-query-detector {
    width: 1200px;
  }
}

.row-fix-clear-both {
  clear: both;
}

table.table-as-row > tbody > tr:before,
table.table-as-row > tbody > tr:after {
  content: " ";
  display: table;
  clear: both;
}

table.table-as-row > tbody > tr {
  height: auto !important;
}

table.table-as-row,
table.table-as-row > tbody,
table.table-as-row > tbody > tr {
  display: block;
  width: auto;
}

table.table-as-row > tbody > tr {
  display: block;
  width: auto;
  margin-right: -15px;
  margin-left: -15px;
}

table.table-as-row > tbody > tr > td[class*=col-] {
  display: block;
  height: auto !important;
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  table.table-as-row > tbody > tr > td[class*=col-lg-] {
    float: left;
  }
}

@media (min-width: 992px) {
  table.table-as-row > tbody > tr > td[class*=col-md-] {
    float: left;
  }
}

@media (min-width: 768px) {
  table.table-as-row > tbody > tr > td[class*=col-sm-] {
    float: left;
  }
}

table.table-as-row > tbody > tr > td[class*=col-xs-] {
  float: left;
}
